import { Injectable } from '@angular/core';

import { Text } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class UrlsService {
  // TODO(gustavoauma): Change this.
  DEFAULT_IMG_URL = 'assets/img/default-img.webp';
  PM_URL = '/pm';
  LOGIN_URL = '/login';
  SIGNUP_URL = '/signup';
  FORGOT_PASSWORD_URL = '/forgot-password';
  CORPORA_URL = '/pm/corpora';
  PM_NOT_FOUND = '/pm/not-found';
  LANDING_PAGE_URL = '/';
  LABEL_SETS_URL = '/pm/label-sets';
  PM_PUBLIC_URL = '/pm/public';
  PUBLIC_CORPORA_URL = '/pm/corpora/public';
  PUBLIC_LABEL_SETS_URL = '/pm/label-sets/public';
  PUBLIC_SPAN_LABELED_URL = '/pm/span-labeled/public';
  PUBLIC_TEXT_CLASSIFICATION_URL = '/pm/text-classification/public';
  TUTORIALS_URLS = '/pm/tutorials';
  SPAN_LABELED_URL = '/pm/span-labeled';
  TEXT_CLASSIFICATION_URL = '/pm/text-classification';
  ST_TASKS_URL = '/st/corpora'; // Switch to SpanLabeled for annotation.
  CL_TASKS_URL = '/cl/corpora'; // Switch to Classification for annotation.
  _TASK_ITEMS_URL = '/pm/task';
  USERS_URL = '/pm/users/u';
  USER_SETTINGS_URL = '/pm/users/user-settings';
  SEARCH_URL = '/pm/search';
  TERMS_URL = '/docs/terms';
  PRIVACY_URL = '/docs/privacy';
  IMPRINT_URL = '/docs/imprint';
  SUPPORT_EMAIL = 'support@datastand.ai';
  SUPPORT_EMAIL_URL = `mailto:${this.SUPPORT_EMAIL}`;

  getClassificationTextUrl(corpusId: number, textId: number): string {
    return `${this.CL_TASKS_URL}/${corpusId}/items/${textId}`;
  }

  getCorpusUrl(corpusId: number): string {
    return `${this.CORPORA_URL}/${corpusId}`;
  }

  getCorpusAboutUrl(corpusId: number): string {
    return `${this.CORPORA_URL}/${corpusId}/about`;
  }

  getCorpusTextsUrl(corpusId: number): string {
    return `${this.CORPORA_URL}/${corpusId}/texts`;
  }

  getCorpusTextUrl(corpusId: number, textId: number): string {
    return `${this.CORPORA_URL}/${corpusId}/texts?id=${textId}`;
  }

  getCorpusTextUrlFromText(text: Text): string {
    return this.getCorpusTextUrl(text.object_id!, text.id);
  }

  getCorpusStatisticsUrl(corpusId: number): string {
    return `${this.CORPORA_URL}/${corpusId}/statistics`;
  }

  getLabelSetUrl(labelSetId: number): string {
    return `${this.LABEL_SETS_URL}/${labelSetId}`;
  }

  getLabelSetAboutUrl(labelSetId: number): string {
    return `${this.LABEL_SETS_URL}/${labelSetId}/about`;
  }

  getLabelSetLabelsUrl(labelSetId: number): string {
    return `${this.LABEL_SETS_URL}/${labelSetId}/labels`;
  }

  getSpanLabeledUrl(corpusId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}`;
  }

  getSpanLabeledAboutUrl(corpusId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}/about`;
  }

  getSpanLabeledLabelsUrl(corpusId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}/labels`;
  }

  getSpanLabeledStatisticsUrl(corpusId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}/statistics`;
  }

  getSpanLabeledStatisticsTextLengthUrl(corpusId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}/statistics/text-length`;
  }

  getSpanLabeledTextsUrl(corpusId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}/texts`;
  }

  getSpanLabeledTextUrl(corpusId: number, textId: number): string {
    return `${this.SPAN_LABELED_URL}/${corpusId}/texts?id=${textId}`;
  }

  getSpanLabeledAnnotationTextUrl(corpusId: number, textId: number): string {
    return `${this.ST_TASKS_URL}/${corpusId}/items/${textId}`;
  }

  getSpanLabeledAnnotationUrlFromText(text: Text): string {
    return this.getSpanLabeledAnnotationTextUrl(text.object_id!, text.id);
  }

  getTextClassificationUrl(corpusId: number): string {
    return `${this.TEXT_CLASSIFICATION_URL}/${corpusId}`;
  }

  getTextClassificationAboutUrl(corpusId: number): string {
    return `${this.TEXT_CLASSIFICATION_URL}/${corpusId}/about`;
  }

  getTextClassificationLabelsUrl(corpusId: number): string {
    return `${this.TEXT_CLASSIFICATION_URL}/${corpusId}/labels`;
  }

  getTextClassificationStatisticsUrl(corpusId: number): string {
    return `${this.TEXT_CLASSIFICATION_URL}/${corpusId}/statistics`;
  }

  getTextClassificationStatisticsTextLengthUrl(corpusId: number): string {
    return `${this.TEXT_CLASSIFICATION_URL}/${corpusId}/statistics/text-length`;
  }

  getTextClassificationTextsUrl(corpusId: number): string {
    return `${this.TEXT_CLASSIFICATION_URL}/${corpusId}/texts`;
  }

  getTextClassificationAnnotationUrl(corpusId: number, textId: number): string {
    return `${this.CL_TASKS_URL}/${corpusId}/items/${textId}`;
  }

  getTextClassificationUrlFromText(text: Text): string {
    return this.getTextClassificationAnnotationUrl(text.object_id!, text.id);
  }

  getTutorialUrl(tutorialId: string): string {
    return `${this.TUTORIALS_URLS}/${tutorialId}`;
  }

  getUserUrl(username: string): string {
    return `${this.USERS_URL}/${username}`;
  }
}
